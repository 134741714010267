.room {
  background-color: rgba(255, 255, 255, 0.945);

  padding: 1em;

  border-radius: 5px;

  .chat {
    display: grid;
    margin-left: 10px;

    @media only screen and (max-width: 600px) {
      margin-top: 10px;
      margin-left: unset;
    }

    grid-template-areas: 
                  "a a a c"
                  "a a a c"
                  "b b b _";

    &.hide-users {
      grid-template-areas: 
                    "a a a"
                    "a a a"
                    "b b b";
    }
                  
    @media only screen and (max-width: 600px) {
      grid-template-areas: 
        "c"
        "a"
        "b";
    }

    gap: px;

    .messages {
      > div {
        padding: 10px;
        
        display: flex;
        flex-direction: column;

        > div {
          border-bottom: 1px solid #eee;
        }

        .them {
          justify-self: right;
          align-self: flex-end;
          width: 75%;
          text-align: right;
          padding:3px 0;
        }
        .you {
          padding:3px 0;
        }
      }
    }


    .message-input {
      display: flex;
      input {
        width: 100%;

        border-radius: 0;
        border: 1px solid #eee;
      }
      button {
        padding: 0.5em 1em;
        outline: none;
        border-radius: 0;
        border: 1px solid rgb(173, 173, 173);
        background-color: rgb(240, 240, 240);
      }
    }

    .users {
      h2 {
        margin-top: 0;
      }
      div {
        padding: 7px;

        background-color: #ffac47;

        p {
          margin: 3px;
        }
      }
    }
  }
}